import { useState } from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

export default function Download(props) {
    const { title, tint, style, file, downloadFileName, tc } = props

    const downloadFile = () => {
        fetch(file, { headers: { "Content-Type": "application/zip" } })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement("a")
                link.href = url
                link.download =
                    downloadFileName ||
                    getFileNameFromUrl(file) ||
                    "download.zip"
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error("Error downloading the file:", error)
            })
    }

    return (
        <motion.div style={{ ...style, ...containerStyle }}>
            <motion.div
                onTap={downloadFile}
                style={{
                    margin: 0,
                    padding: "16px 51px",
                    borderRadius: 12,
                    backgroundColor: tint,
                    color: tc,
                    fontSize: "22px",
                    fontWeight: "normal",
                    lineHeight: "27px",
                    letterSpacing: "-0.679px",
                    width: "100%",
                    height: "100%",
                    transition: "all 0.125s ease-in-out",
                    cursor: "pointer",
                    textAlign: "center",
                }}
            >
                <span style={{ pointerEvents: "none" }}>{title}</span>{" "}
            </motion.div>
        </motion.div>
    )
}

Download.defaultProps = {
    tint: "#09F",
    tc: "#fff",
    title: "Download",
    downloadFileName: "",
}

addPropertyControls(Download, {
    tint: {
        title: "Tint",
        type: ControlType.Color,
    },
    tc: {
        title: "TextColor",
        type: ControlType.Color,
    },
    title: {
        title: "Label",
        type: ControlType.String,
    },
    file: {
        title: "File",
        type: ControlType.File,
        allowedFileTypes: [],
    },
    downloadFileName: {
        title: "Download File Name",
        type: ControlType.String,
        defaultValue: "",
    },
})

const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}

function getFileNameFromUrl(url) {
    const decodedUrl = decodeURIComponent(url)
    const parts = decodedUrl.split("/")
    return parts[parts.length - 1]
}
